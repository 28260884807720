<template>
  <div></div>
</template>
<script>
const XLSX = require('xlsx');
import FormatUtils from '@/utils/FormatUtils';
import {getPaymentType} from "@/utils/PaymentType";

export default {
    name: 'XlsxUtils',
    mixins: [FormatUtils],
    data() {
        return {
            format: '.xlsx',
            companyInfo: [
                'ABN AMRO Bank N.V.',
                'PayDay',
                'Name of the contact',
                '06-1234567890',
                '+31 612345678',
                'name.lastname@nl.abnamro.com',
                'NL68ABNA3675462129',
                'NLABNA123',
                'Other Info',
            ],
            activeWorkerHeader: ['id', 'creationDate', 'company', 'userType', 'firstname', 'lastname', 'email', 'iban', 'balance', 'onboarded', 'status'],
            activeWorkerProperties: {
                Title: 'PayDay - Active Users',
                Subject: 'Exported active users',
                Author: 'PayDay',
                Keywords: 'payday, active, users',
                createdDate: new Date()
            },
            inactiveWorkerHeader: ['id', 'creationDate', 'company', 'userType', 'firstname', 'lastname', 'email', 'iban', 'status'],
            inactiveWorkerProperties: {
                Title: 'PayDay - Inactive Users',
                Subject: 'Exported Inactive users',
                Author: 'PayDay',
                Keywords: 'payday, Inactive, users',
                createdDate: new Date()
            },
            transactionHeader: ['User ID', 'Ref ID', 'Company', 'Name', 'Description', 'Iban', 'Creation Date', 'Update Date', 'Exec Date', 'Type', 'Amount', 'Status', 'Status reason'],
            transactionProperties: {
                Title: 'PayDay - Transactions',
                Subject: 'Exported Transactions',
                Author: 'PayDay',
                Keywords: 'payday, transactions',
                createdDate: new Date()
            },
            errorPisHeader: ['User ID', 'company', 'name', 'Creation Date', 'Update Date', 'Type', 'Amount', 'Status', 'Tries',],
            errorPisProperties: {
                Title: 'PayDay - Errors PIS',
                Subject: 'Exported PIS Errors',
                Author: 'PayDay',
                Keywords: 'payday, error, pis',
                createdDate: new Date()
            },
            errorServerHeader: ['ErrorID', 'company', 'type', 'Description', 'HTTP Status', 'Date'],
            errorServerProperties: {
                Title: 'PayDay - Errors Server',
                Subject: 'Exported Server Errors',
                Author: 'PayDay',
                Keywords: 'payday, error, server',
                createdDate: new Date()
            },
            workerProperties: {
                Title: 'PayDay - Worker Profile Export',
                Subject: 'Worker Profile Export',
                Author: 'PayDay',
                Keywords: 'payday, worker, profile',
                createdDate: new Date()
            },
            workerProfileHeaderXlsx: ['User ID', 'Company', 'Firstname', 'Lastname', 'Email', 'PhoneNumber', 'Iban',],
            workerGigHeaderXlsx: ['Id', 'UpdateTime', 'StartTime', 'EndTime', 'HourRate', 'GrossIncome', 'ProvisionalIncome', 'Status',],
            walletGigHeaderXlsx: ['Date', 'Description', 'Amount'],
            payrollHeaderXlsx: ['Payroll Id', 'User Id', 'Name', 'Date', 'Period', 'Net Income', 'Incoming', 'Outgoing', 'Balance', 'Status'],
            payrollProperties: {
                Title: 'PayDay - Users Payrolls',
                Subject: 'Exported Users Payrolls',
                Author: 'PayDay',
                Keywords: 'payday, payroll, users',
                createdDate: new Date()
            },
            balanceAndDepositHeaderXlsx: ['Deposit Id', 'Date', 'Description', 'Type', 'Amount', 'Status'],
            balanceAndDepositProperties: {
                creator: 'PayDay',
                Title: 'PayDay - Deposit & Balance Details',
                Subject: 'Exported Deposit & Balance details',
                Author: 'PayDay',
                Keywords: 'payday, deposit, balance, details',
                createdDate: new Date()
            },
            notificationHeaderXlsx: ['Notification Id', 'User Id', 'Platform', 'Type', 'Title'],
            notificationProperties: {
                creator: 'PayDay',
                Title: 'PayDay - Notifications Details',
                Subject: 'Exported Notifications details',
                Author: 'PayDay',
                Keywords: 'payday, notifications, details',
                createdDate: new Date()
            },
            invoicePropertiesXlsx: {
                creator: 'Payday',
                Title: 'PayDay - Invoice Details',
                Subject: 'Exported Invoice details',
                Author: 'PayDay',
                Keywords: 'PayDay, Invoices',
                createdDate: new Date()
            },
            invoiceHeaderXlsx: [
                'P. user Id', // 0
                'Name', // 1
                'Invoice Number', // 2
                'Invoice Date', // 3
                'Due Date', // 4
                'Payout Date', // 5
                'Amount', // 6
                'Fee', // 7
                'Status', // 8
                'Company', // 9
                'Employer' // 10
            ],
            payrollHeaderForIncoming: [
                'Id', 'User Id',
                'Name', 'Iban', 'Company',
                'CreatedDate', 'Payment Type',
                'Employer', 'GroupId',
                'Description', 'Amount',
                'Status',
            ],
            payrollHeaderForPayout: [
                'Id', 'User Id',
                'Name', 'Company',
                'Creation Date', 'Payment Type',
                'Employer', 'GroupId',
                'Description', 'Amount',
                'Status',
            ],
            payrollHeaderForNets: [
                'Date', 'Net Pay', 'Amount'
            ]
        };
    },
    methods: {
        mapActiveWorkers(selection) {
            return selection.map(worker => [
                worker.id.toString(),
                new Date(worker.creationDate).toLocaleString('nl-NL'),
                worker.company,
                worker.userType,
                worker.firstname,
                worker.lastname,
                worker.email,
                worker.iban,
                worker.balance,
                worker.onBoarded ? 'Yes' : 'No',
                worker.status
            ]);
        },
        mapInactiveWorkers(selection) {
            return selection.map(worker => [
                worker.id.toString(),
                new Date(worker.creationDate).toLocaleString('nl-NL'),
                worker.company,
                worker.userType,
                worker.firstname,
                worker.lastname,
                worker.email,
                worker.iban,
                `${worker.status}`,
            ]);
        },
        getDateByTransaction(date) {
            return date ? date.replaceAll('-', '/')
                .replace('T', ' ') : '-';
        },
        mapTransactions(selection, getCompanyName, getStatus) {
            return selection.map(transaction => [
                transaction.uid.toString(),
                transaction.refid ? transaction.refid.toString() : '-',
                getCompanyName(transaction.companyId),
                transaction.counterpartyName,
                transaction.description ? transaction.description.toString() : 'No Description',
                transaction.counterpartyIban,
                this.getDateByTransaction(transaction.creationDate),
                this.getDateByTransaction(transaction.updateDate),
                this.getDateByTransaction(transaction.executionDate),
                this.$t(getPaymentType(transaction.paymentType).name),
                `${this.getAmountForExporter(transaction.amount || transaction.totalAmount)}`,
                `${getStatus(transaction.status)}`,
                `${transaction.reasonNote}`,
            ]);
        },
        mapErrorServer(selection, getCompanyName, getErrorType) {
            return selection.map(transactionError => [
                transactionError.id.toString(),
                getCompanyName(transactionError.companyId),
                getErrorType(transactionError.errorType),
                transactionError.description,
                transactionError.httpStatus,
                transactionError.creationDate,
            ]);
        },
        mapSelectionToGig(selection) {
            return selection.map(gig => {
                const startTime = new Date(gig.paycInitiator.submittedStartTime);
                const endTime = new Date(gig.paycInitiator.submittedEndTime);
                const date = new Date(gig.updateDate).toLocaleDateString('nl-NL');
                return {
                    'id': gig.id,
                    'date': date,
                    'startTime': `${startTime.toLocaleDateString('nl-NL')} - ${startTime.getHours()}:${this.getMinute(startTime.getMinutes())}`,
                    'endTime': `${endTime.toLocaleDateString('nl-NL')} - ${endTime.getHours()}:${this.getMinute(endTime.getMinutes())}`,
                    'hourRate': this.getAmountForExporter(gig.paycInitiator.hourlyRate),
                    'provisionalGrossIncome': this.getAmountForExporter(gig.paycInitiator.provisionalGrossIncome),
                    'provisionalIncome': this.getAmountForExporter(gig.totalAmount || gig.amount),
                    'paymentStatus': gig.status
                };
            })
                .map(gig => [
                    gig.id, gig.date, gig.startTime, gig.endTime, gig.hourRate,
                    gig.provisionalGrossIncome, gig.provisionalIncome, gig.paymentStatus
                ]);
        },
        mapSelectionToWallet(selection) {
            return selection.transactionsInfo.gigs.map(gig => ({
                'date': new Date(gig.updateDate).toLocaleDateString('nl-NL'),
                'description': gig.description,
                'amount': this.getAmountForExporter(gig.totalAmount || gig.amount)
            }))
                .map(gig => [gig.date, gig.description, gig.amount]);
        },
        mapSelectionToPayrollXlsx(selection, getStatus, getBalance) {
            return selection.map(payroll => [
                payroll.id.toString(),
                payroll.uid.toString(),
                payroll.name,
                new Date(payroll.creationDate,).toLocaleDateString('nl-NL'),
                payroll.groupId,
                this.getAmountForExporter(payroll.netIncome,),
                this.getAmountForExporter(payroll.walIn,),
                this.getAmountForExporter(payroll.walOut,),
                getBalance(payroll),
                this.$t(getStatus(payroll.status,).name)
            ]);
        },
        mapSelectionToBalanceAndDeposit(selection) {
            return selection.map(deposit => [
                deposit.depositId.toString(),
                new Date(deposit.creationDate).toLocaleDateString('nl-NL'),
                deposit.description,
                deposit.depositType,
                this.getAmountForExporter(deposit.amount),
                deposit.status]);
        },
        mapSelectionToNotifications(selection) {
            return selection.map(notification => [
                notification.id,
                notification.mid,
                notification.platform,
                `${notification.type}/${notification.subType}`,
                notification.title,
            ]);
        },
        mapSelectionToInvoices(selection, getStatus, getCompany, getCorrectPaymentDate) {
            return selection.map(data =>
                [
                    data.uid,
                    `${data.firstname} ${data.lastname}`,
                    data.refid,
                    new Date(data.invoiceDate),
                    new Date(data.payAssignment.payDate),
                    getCorrectPaymentDate(data),
                    parseFloat(data.amount ? `${data.amount}` : '0.00'),
                    `${data.correctPercentageFee}`,
                    getStatus(data.isPaid, data.isEarlyPaid, data.isPayout).name,
                    getCompany(data.companyId),
                    data.payAssignment.employerName
                ]);
        },
        mapPayrollToIncome(selection, getStatus, getCompany) {
            const companyName = getCompany(selection.companyId);
            return selection.walInPayments.map(payroll => [
                payroll.id, payroll.uid, payroll.counterpartyName, payroll.counterpartyIban,
                companyName, payroll.creationDate, payroll.paymentType,
                payroll.accountHolderName, selection.groupId, payroll.description,
                payroll.totalAmount, this.$t(getStatus(payroll.status).name)
            ]);
        },
        mapPayrollToPayout(selection, getStatus, getCompany) {
            const companyName = getCompany(selection.companyId);
            return selection.walOutTransactions.map(payroll => [
                payroll.id, payroll.uid, payroll.counterpartyName,
                companyName, payroll.creationDate, payroll.paymentType,
                payroll.accountHolderName, selection.groupId, payroll.description,
                payroll.amount,this.$t(getStatus(payroll.status).name)
            ]);
        },
        mapPayrollToNetPay(selection) {
            return selection.map(net => [
                net.creationDate, 'Net Pay', net.amount
            ]);
        },
        getXlsxForActiveWorkers(data, title) {
            const formattedData = this.mapActiveWorkers(data);
            formattedData.unshift(this.activeWorkerHeader);
            // UserId, Balance
            const listOfColumnsThatWillBeNumeric = [0, 8];
            const xlxs = this.buildXlsx(title, formattedData, this.activeWorkerProperties, listOfColumnsThatWillBeNumeric);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForInactiveWorkers(data, title) {
            const formattedData = this.mapInactiveWorkers(data);
            formattedData.unshift(this.inactiveWorkerHeader);
            const xlxs = this.buildXlsx(title, formattedData, this.activeWorkerProperties, []);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForTransaction(data, title, getCompanyName, getStatus) {
            const formattedData = this.mapTransactions(data, getCompanyName, getStatus);
            formattedData.unshift(this.transactionHeader);
            // UserId, Amount
            const listOfColumnsThatWillBeNumeric = [0, 10];
            const xlxs = this.buildXlsx(title, formattedData, this.activeWorkerProperties, listOfColumnsThatWillBeNumeric);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForServerContent(data, title, getCompanyName, getErrorType) {
            const formattedData = this.mapErrorServer(data, getCompanyName, getErrorType);
            formattedData.unshift(this.errorServerHeader);
            const xlxs = this.buildXlsx(title, formattedData, this.activeWorkerProperties, [0]);
            this.downloadXlsx(xlxs, title + this.format);
        },
        buildXlsx(filename, formattedData, properties, listOfColumnsThatWillBeNumeric) {
            const sheetName = filename.split('_')[0];
            const wb = XLSX.utils.book_new();
            wb.Props = properties;
            wb.SheetNames.push('Info');
            wb.SheetNames.push(sheetName);
            const formattedInfo = this.companyInfo.map(info => [info]);
            wb.Sheets['Info'] = XLSX.utils.aoa_to_sheet(formattedInfo);
            wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(
                formattedData,
                { cellDates: true }
            );
            const ws = wb.Sheets[sheetName];
            listOfColumnsThatWillBeNumeric.forEach(col => this.makeColumnAsNumber(col, formattedData.length, ws));
            const writtenData = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary'
            });
            return this.convertToOctetStream(writtenData);
        },
        makeColumnAsNumber(column, listOfRows, ws) {
            // Create numeric Format
            const fmt = (column === 0 || column === 1) ? '0' : '€ 0.00';
            // Set Range
            const range = {
                s: { // start
                    r: 1, // row
                    c: column // column
                },
                e: { // end
                    r: listOfRows - 1, // row
                    c: column //column
                }
            };
            // Assign range just for readbility
            const startingRow = range.s.r;
            const finishRow = range.e.r;
            const startingCol = range.s.c;
            const finishCol = range.e.c;
            for (let R = startingRow; R <= finishRow; ++R) {
                for (let C = startingCol; C <= finishCol; ++C) {
                    const cell = ws[XLSX.utils.encode_cell({
                        r: R,
                        c: C
                    })];
                    // set 'n' as numeric
                    cell.t = 'n';
                    // set basic format
                    cell.z = fmt;
                }
            }
        },
        convertToOctetStream(s) {
            const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
            const view = new Uint8Array(buf);  //create uint8array as viewer
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        },
        downloadXlsx(content, fileName, mimeType) {
            const a = document.createElement('a');
            const mimeTypeDefine = mimeType || 'application/octet-stream';

            if (navigator.msSaveBlob) { // IE10
                navigator.msSaveBlob(new Blob([content], { type: mimeTypeDefine }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], { type: mimeTypeDefine }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        },
        exportWorkerDetailXlsx(selection, workerInfo, workerGigs, workerWallet) {
            const fullDate = this.getFormattedDate();
            const infoSheetName = 'Info';
            const wb = XLSX.utils.book_new();
            wb.Props = this.workerProperties;
            wb.SheetNames.push(infoSheetName);
            const formattedInfo = this.companyInfo.map(info => [info]);
            wb.Sheets[infoSheetName] = XLSX.utils.aoa_to_sheet(formattedInfo);
            if (selection.includes('profile')) {
                this.printWorkerProfileDetailXlsx(wb, workerInfo);
            }
            if (selection.includes('gigs')) {
                this.printWorkerGigsXlsx(wb, workerGigs);
            }
            if (selection.includes('wallet')) {
                this.printWorkerWalletXlsx(wb, workerWallet);
            }
            const writtenData = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary'
            });
            const xlxs = this.convertToOctetStream(writtenData);
            this.downloadXlsx(xlxs, `worker_profile_${workerInfo.lastname}_${fullDate}_${this.format}`);
        },
        /* eslint-disable no-param-reassign */
        printWorkerProfileDetailXlsx(wb, workerInfo) {
            const sheetName = 'Profile';
            const formattedData = [[`${workerInfo.id.toString()}`, `${workerInfo.company}`, `${workerInfo.firstname}`, `${workerInfo.lastname}`, `${workerInfo.email}`, `${workerInfo.phoneNumber}`, `${workerInfo.iban}`]];
            formattedData.unshift(this.workerProfileHeaderXlsx);
            wb.SheetNames.push(sheetName);
            wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(formattedData);
            const ws = wb.Sheets[sheetName];
            const listOfColumnsThatWillBeNumeric = [0];
            listOfColumnsThatWillBeNumeric.forEach(col => this.makeColumnAsNumber(col, formattedData.length, ws));
        },
        printWorkerGigsXlsx(wb, workerGigs) {
            const sheetName = 'Gigs';
            const formattedData = this.mapSelectionToGig(workerGigs);
            formattedData.unshift(this.workerGigHeaderXlsx);
            wb.SheetNames.push(sheetName);
            // Id, HourRate, GrossIncome, ProvisionalIncome
            const listOfColumnsThatWillBeNumeric = [0, 4, 5, 6];
            wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(formattedData);
            const ws = wb.Sheets[sheetName];
            listOfColumnsThatWillBeNumeric.forEach(col => this.makeColumnAsNumber(col, formattedData.length, ws));
        },
        printWorkerWalletXlsx(wb, workerWallet) {
            const sheetName = 'Wallet';
            const formattedData = this.mapSelectionToWallet(workerWallet);
            formattedData.unshift(this.walletGigHeaderXlsx);
            wb.SheetNames.push(sheetName);
            wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(formattedData);
            // Amount
            const listOfColumnsThatWillBeNumeric = [2];
            const ws = wb.Sheets[sheetName];
            listOfColumnsThatWillBeNumeric.forEach(col => this.makeColumnAsNumber(col, formattedData.length, ws));
        },
        /* eslint-enable no-param-reassign */
        getXlsxForPayrollXlsx(data, title, getStatus, getBalance) {
            const formattedData = this.mapSelectionToPayrollXlsx(data, getStatus, getBalance);
            formattedData.unshift(this.payrollHeaderXlsx);
            // PayrollId, UserId, Estimated Income, Net Adjm Income
            const listOfColumnsThatWillBeNumeric = [0, 1, 5, 6, 7];
            const xlxs = this.buildXlsx(title, formattedData, this.payrollProperties, listOfColumnsThatWillBeNumeric);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForBalanceAndDeposit(data, title) {
            const formattedData = this.mapSelectionToBalanceAndDeposit(data);
            formattedData.unshift(this.balanceAndDepositHeaderXlsx);
            // DepositId, Amount
            const listOfColumnsThatWillBeNumeric = [0, 4];
            const xlxs = this.buildXlsx(title, formattedData, this.balanceAndDepositProperties, listOfColumnsThatWillBeNumeric);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForNotificationContent(data, title) {
            const formattedData = this.mapSelectionToNotifications(data);
            formattedData.unshift(this.notificationHeaderXlsx);
            const listOfColumnsThatWillBeNumeric = [0, 1];
            const xlxs = this.buildXlsx(title, formattedData, this.notificationProperties, listOfColumnsThatWillBeNumeric);
            this.downloadXlsx(xlxs, title + this.format);
        },
        getXlsxForInvoice(data, title, getStatus, getCompany, getCorrectPaymentDate) {
            const formattedData = this.mapSelectionToInvoices(data, getStatus, getCompany, getCorrectPaymentDate);
            formattedData.unshift(this.invoiceHeaderXlsx);
            const listOfColumnsThatWillBeNumeric = [0, 6];
            const xlxs = this.buildXlsx(
                title,
                formattedData,
                this.invoicePropertiesXlsx,
                listOfColumnsThatWillBeNumeric
            );

            this.downloadXlsx(xlxs, title + this.format);
        },
        exportPayrollXlsx(payroll, title, getStatus, getCompany, nets) {
            const formattedIncoming = this.mapPayrollToIncome(payroll, getStatus, getCompany);
            formattedIncoming.unshift(this.payrollHeaderForIncoming);
            const formattedPayout = this.mapPayrollToPayout(payroll, getStatus, getCompany);
            formattedPayout.unshift(this.payrollHeaderForPayout);
            const formattedNets = this.mapPayrollToNetPay(nets);
            formattedNets.unshift(this.payrollHeaderForNets);
            const listOfColumnsThatWillBeNumericForInAndOut = [0, 1, 9]; // Id, User Id, Amount
            const listOfColumnsThatWillBeNumericForNet = [2]; // Amount
            const contentToBuild = {
                Incoming: {
                    list: formattedIncoming,
                    numeric: listOfColumnsThatWillBeNumericForInAndOut
                },
                Payout: {
                    list: formattedPayout,
                    numeric: listOfColumnsThatWillBeNumericForInAndOut
                },
                Net: {
                    list: formattedNets,
                    numeric: listOfColumnsThatWillBeNumericForNet
                }
            };
            const xlxs = this.buildXlsxMultipleTabs(contentToBuild, this.invoicePropertiesXlsx);
            this.downloadXlsx(xlxs, title + this.format);
        },
        buildXlsxMultipleTabs(content, properties) {
            const wb = XLSX.utils.book_new();
            wb.Props = properties;
            wb.SheetNames.push('Info');
            Object.keys(content)
                .forEach(tabName => {
                    wb.SheetNames.push(tabName);
                });

            const formattedInfo = this.companyInfo.map(info => [info]);
            wb.Sheets['Info'] = XLSX.utils.aoa_to_sheet(formattedInfo);

            for (const [key, value] of Object.entries(content)) {
                wb.Sheets[key] = XLSX.utils.aoa_to_sheet(value.list);
                value.numeric.forEach(col =>
                    this.makeColumnAsNumber(col, value.length, wb.Sheets[key])
                );
            }
            const writtenData = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary'
            });
            return this.convertToOctetStream(writtenData);
        },
    }
};
</script>
